import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { registerInvitation } from '../../app/auth';
import queryString from 'query-string';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
import './Invitation.css';
import PropTypes from 'prop-types';
import CookieConsentBar from '../../components/CookieConsentBar';

const Invitation = () => {
  const location = useLocation();
  const [invitationCode, setInvitationCode] = useState('');
  const [username, setUsername] = useState('');
  const [client, setClient] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  useEffect(() => {
    const values = queryString.parse(location.search);
    setUsername(values.username || '');
    setInvitationCode(values.code || '');
    setClient(values.client || '');
  }, [location.search]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check the passwords are the same
    if (password !== confirmPassword) {
      setErrorMsg('Passwords do not match');
      return;
    }
    // Show loading spinner
    setIsLoading(true);

    const res = await registerInvitation(invitationCode, password);
    if (res.status_code !== 200) {
      setErrorMsg(res.message);
      setIsLoading(false);
    } else {
      setRegisterSuccess(true);
    }
  };

  if (registerSuccess) {
    return (
      <Navigate
        to="/login"
        state={{
          registerMsg: 'Successfully registered, please login.',
        }}
      />
    );
  }

  let loadingSpinner;
  if (isLoading) {
    loadingSpinner = <Spinner color="primary" />;
  }

  return (
    <div className="container">
      <div className="register-parent">
        <Card className="register-card">
          <CardBody className="register-title">
            <h5>Register your account!</h5>
            <br />
            <Form action="#" onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="client" className={'black-label'}>
                  Organisation
                </Label>
                <Input type="text" name="organisation" id="client" readOnly value={client} />
              </FormGroup>
              <FormGroup>
                <Label for="username" className={'black-label'}>
                  Email
                </Label>
                <Input type="text" name="Email" id="Email" readOnly value={username} />
              </FormGroup>
              <FormGroup>
                <Label for="password" className={'black-label'}>
                  Password (Must be good or better)
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handlePasswordChange}
                />
                <div className="meter" id="strengthMeter">
                  <PasswordStrengthMeter className="password-strength-meter" password={password} />
                </div>
                <Label for="confirm-password" className={'black-label'}>
                  Confirm Password
                </Label>
                <Input
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  onChange={handleConfirmPasswordChange}
                />
              </FormGroup>
              <FormGroup>
                {errorMsg.length > 0 && <p className="error-msg">{errorMsg}</p>}
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <Button type="submit">Register</Button>
                  </Col>
                  <Col>{loadingSpinner}</Col>
                </Row>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
      <CookieConsentBar />
    </div>
  );
};

Invitation.propTypes = {
  location: PropTypes.object,
};

export default Invitation;
